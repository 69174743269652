import React, { useState, useEffect } from 'react';
import { socket } from './socket';

const App: React.FC = () => {
    const [nickname, setNickname] = useState<string>('');
    const [roomName, setRoomName] = useState<string>('');
    const [rooms, setRooms] = useState<string[]>([]);
    const [currentRoom, setCurrentRoom] = useState<string | null>(null);
    const [isGameInProgress, setIsGameInProgress] = useState<boolean>(false);
    const [currentRound, setCurrentRound] = useState<number>(0);
    const [roundCount, setRoundCount] = useState<number>(0);
    const [currentSong, setCurrentSong] = useState<{ title: string, youtubeLink: string, initialHint: string, artistHint: string } | null>(null);
    const [answer, setAnswer] = useState<string>('');
    const [players, setPlayers] = useState<{ nickname: string, score: number }[]>([]);

    useEffect(() => {
        // 방 목록 수신
        socket.on('roomList', (roomList: string[]) => {
            setRooms(roomList);
        });

        socket.on('updateRoom', (room: { name: string, users: { nickname: string, score: number }[] }) => {
            setCurrentRoom(room.name);
            setPlayers(room.users);
        });

        socket.on('gameStarted', (totalRounds: number, round: number, song: { title: string, youtubeLink: string, initialHint: string, artistHint: string }) => {
            setIsGameInProgress(true);
            setRoundCount(totalRounds);
            setCurrentRound(round);
            setCurrentSong(song);
        });

        socket.on('nextRound', (round: number, song: { title: string, youtubeLink: string, initialHint: string, artistHint: string }) => {
            setCurrentRound(round);
            setCurrentSong(song);
        });

        socket.on('correctAnswer', (nickname: string, score: number) => {
            alert(`${nickname}님이 정답을 맞추셨습니다!`);
            setPlayers(prevPlayers => prevPlayers.map(p => p.nickname === nickname ? { ...p, score } : p));
        });

        socket.on('gameEnded', (users: { nickname: string, score: number }[]) => {
            alert('게임 종료!');
            setPlayers(users);
            setIsGameInProgress(false);
        });

        socket.on('error', (message: string) => {
            alert(message);
        });

        return () => {
            socket.off('roomList');
            socket.off('updateRoom');
            socket.off('gameStarted');
            socket.off('nextRound');
            socket.off('correctAnswer');
            socket.off('gameEnded');
            socket.off('error');
        };
    }, []);

    const handleCreateRoom = () => {
        const roundCount = 5; // 기본 라운드 수
        socket.emit('createRoom', roomName, nickname, roundCount);
    };

    const handleJoinRoom = (name: string) => {
        socket.emit('joinRoom', name, nickname);
    };

    const handleStartGame = () => {
        if (currentRoom) {
            socket.emit('startGame', currentRoom);
        }
    };

    const handleSubmitAnswer = () => {
        if (currentRoom) {
            socket.emit('submitAnswer', currentRoom, nickname, answer);
            setAnswer('');
        }
    };

    const handleSkipSong = () => {
        if (currentRoom) {
            socket.emit('skipSong', currentRoom, nickname);
        }
    };

    const handleEndGame = () => {
        if (currentRoom) {
            socket.emit('endGame', currentRoom);
        }
    };

    return (
        <div>
            {!currentRoom ? (
                <div>
                    <input 
                        type="text" 
                        placeholder="닉네임" 
                        value={nickname} 
                        onChange={(e) => setNickname(e.target.value)} 
                    />
                    <input 
                        type="text" 
                        placeholder="방 이름" 
                        value={roomName} 
                        onChange={(e) => setRoomName(e.target.value)} 
                    />
                    <button onClick={handleCreateRoom}>방 만들기</button>
                    <h3>방 목록</h3>
                    <ul>
                        {rooms.map(room => (
                            <li key={room} onClick={() => handleJoinRoom(room)}>{room}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div>
                    <h1>{currentRoom} 방</h1>
                    <h2>라운드 {currentRound}/{roundCount}</h2>
                    {isGameInProgress && currentSong && (
                        <div>
                            <p>힌트: {currentSong.initialHint} ({currentSong.artistHint})</p>
                            <input 
                                type="text" 
                                placeholder="노래 제목" 
                                value={answer} 
                                onChange={(e) => setAnswer(e.target.value)} 
                            />
                            <button onClick={handleSubmitAnswer}>제출</button>
                            <button onClick={handleSkipSong}>스킵</button>
                        </div>
                    )}
                    <button onClick={handleStartGame} disabled={isGameInProgress}>게임 시작</button>
                    <button onClick={handleEndGame} disabled={!isGameInProgress}>게임 종료</button>
                    <h3>참여자 목록</h3>
                    <ul>
                        {players.map(player => (
                            <li key={player.nickname}>{player.nickname} - {player.score}점</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default App;
